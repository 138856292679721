import {
  PLANS_ACTIVE, STANDARD_PLAN_FEATURES, CUSTOM_PLAN_FEATURES, ONE_OFF_STEPS
} from '@/settings/constants'

export default {
  data () {
    return {
      oneOffCreditsSlider: 2,
      ONE_OFF_STEPS
    }
  },

  computed: {
    /**
     * The features which are available in the specific plans, and their value
     * Mapping of
     * {planID} ->
     *   {featureName} ->
     *     featureValue (if there's a specific value) || true (if it's just available) || false (if not available)
     * @return {Object}
     */
    showPlanFeatures () {
      let result = {}
      let featureSeen = new Set()
      PLANS_ACTIVE.forEach(pID => {
        result[pID] = {}
        STANDARD_PLAN_FEATURES.forEach(fName => {
          // For the standard plan features we check if they are available in a plan or not
          // If the feature is not at in the planFeatures object at all, we assume it's a global
          // feature which is available for all plans
          let featureAvailableInPlan = !(fName in this.planFeatures[pID]) || this.planFeatures[pID][fName]
          if (featureAvailableInPlan) {
            let serializedFeature = `${fName}_${JSON.stringify(this.planFeatures[pID][fName])}`
            // We only show the feature in the plan, if it is "new", i.e. has not been displayed
            // in exactly the same manner for a lower plan
            result[pID][fName] = !featureSeen.has(serializedFeature) && featureAvailableInPlan
            featureSeen.add(serializedFeature)
          }
        })

        // The custom plan features are always displayed for those specific plans
        CUSTOM_PLAN_FEATURES[pID].forEach(feature => {
          // The values are either a Boolean, if the feature was just a string
          // or we take the "value" of the feature, if it was an Object
          if (_.isObject(feature)) result[pID][feature.key] = feature.value
          else result[pID][feature] = true
        })
      })

      return result
    },

    showAllPlanFeatures () {
      let result = {}
      PLANS_ACTIVE.forEach(pID => {
        result[pID] = {}
        STANDARD_PLAN_FEATURES.forEach(fName => {
          let featureAvailableInPlan = !(fName in this.planFeatures[pID]) || this.planFeatures[pID][fName]
          if (featureAvailableInPlan) result[pID][fName] = featureAvailableInPlan
        })

        // The custom plan features are always displayed for those specific plans
        CUSTOM_PLAN_FEATURES[pID].forEach(feature => {
          // The values are either a Boolean, if the feature was just a string
          // or we take the "value" of the feature, if it was an Object
          if (_.isObject(feature)) result[pID][feature.key] = feature.value
          else result[pID][feature] = true
        })
      })

      return result
    },

    /**
     * The name of the next lower plan, if it exists, otherwies false
     * @return {String,Boolean}
     */
    previousPlus () {
      let result = {}
      PLANS_ACTIVE.forEach((pID, idx) => {
        result[pID] = idx > 0 ? this.planFeatures[PLANS_ACTIVE[idx - 1]].name : false
      })
      return result
    },

    /**
     * The maximal value (not displayed value, but real value) that the one-off slider can display
     * @return {Number}
     */
    oneoffCreditsSliderMax () {
      if (this.user.subscription.plan_id === 130 || 'slider_max' in this.$route.query) return ONE_OFF_STEPS.length - 1
      else return ONE_OFF_STEPS.length - 6
    },

    /**
     * The total for the one-off purchase with the current slider settings (wihtout VAT)
     * @return {Number}
     */
    oneOffPrice () {
      return Math.round(this.oneOffPricePerCredit * this.oneOffCredits)
    },

    /**
     * The price per credit for the current threshold value
     * @return {Number}
     */
    oneOffPricePerCredit () {
      return _.findLast(this.CREDIT_PRICES, e => this.oneOffCredits >= e.thresh).price
    },

    /**
     * The abstracted value of the one-off slider
     * @return {Number}
     */
    oneOffCredits () {
      return ONE_OFF_STEPS[this.oneOffCreditsSlider]
    },

    /**
     * The nicely formatted number of one-off credits, as string
     * @return {String}
     */
    oneOffCreditsDisplayed () {
      return this.formatCreditCount(this.oneOffCredits)
    }
  },

  methods: {
    /**
     * Return a nicely formatted string, given the number of credits (e.g. 500, 50k)
     * @param  {Number} credits The number of credits
     * @return {String}
     */
    formatCreditCount (credits) {
      return credits >= 10000 ? `${credits / 1000}k` : credits
    }
  }
}