const API_HOSTS = {
  local: '127.0.0.1:8000',
  dev: 'api.dev.caplena.dev',
  stage: 'api.stage.caplena.dev',
  prod: 'api.caplena.com'
}

const HOST = process.env.VUE_APP_HOST || '127.0.0.1'
module.exports = {
  API_HOSTS,
  API_HTTP_BASE_URIS: {
    local: `http://${HOST}:8080`,
    dev: `https://${API_HOSTS.dev}`,
    stage: `https://${API_HOSTS.stage}`,
    prod: `https://${API_HOSTS.prod}`
  },
  API_WS_BASE_URIS: {
    local: `ws://${HOST}:8080`,
    dev: `wss://${API_HOSTS.dev}`,
    stage: `wss://${API_HOSTS.stage}`,
    prod: `wss://${API_HOSTS.prod}`
  }
}
